<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    dark
    app
    v-bind="$attrs"
  >
    <v-divider class="mb-2" />
    <v-img src="@/assets/logoLiquidPark2.png" :width="150" class="ma-auto" />
    <v-divider class="my-1" />

    <v-list dense nav>
      <v-list-item link to="/">
        <v-list-item-icon>
          <v-icon> mdi-view-dashboard </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Dashboard </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-for="(item, i) in menu">
        <template v-if="item.itens">
          <v-list-group
            :key="`group-${i}`"
            no-action
            :prepend-icon="item.icone"
            active-class="primary--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.texto" />
              </v-list-item-content>
            </template>

            <template v-for="(submenu, si) in item.itens">
              <v-list-item
                v-if="submenu.mostrar == true"
                :key="`submenu-${si}`"
                :to="submenu.link"
                active-class="primary white--text"
              >
                <v-list-item-icon>
                  <v-icon v-text="submenu.icone" />
                </v-list-item-icon>

                <v-list-item-title v-text="submenu.texto" />
              </v-list-item>
            </template>
          </v-list-group>
        </template>

        <template v-else>
          <v-list-item
            :key="`group-${i}`"
            :to="item.link || ''"
            active-class="primary white--text"
            link
          >
            <v-list-item-icon>
              <v-icon v-text="item.icone" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.texto" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
      <v-card-actions class="ma-0 py-4">
        <!-- <v-switch -->
        <v-checkbox
          v-model="$vuetify.theme.dark"
          off-icon="mdi-white-balance-sunny"
          on-icon="mdi-weather-night"
          primary
          class="ma-0 pa-0"
          :label="themaLabel"
        />
      </v-card-actions>
    </v-list>

    <div class="drawer-bottom">
      <v-divider />
      <div class="d-flex align-center">
        <v-icon class="pa-1"> mdi-email</v-icon>
        <p class="text-subtitle-2 ma-0">
          <a
            href="mailto: Suporte@liquidworks.com.br"
            class="text-decoration-none white--text"
          >
            Suporte@liquidworks.com.br
          </a>
        </p>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import menu from "@/utils/menu";
export default {
  data() {
    return {
      menu: menu(),
    };
  },

  computed: {
    drawer: {
      get() {
        return this.$store.state.layout.drawer;
      },
      set(val) {
        this.$store.commit("layout/SET_DRAWER", val);
      },
    },
    themaLabel() {
      return this.$vuetify.theme.dark ? "Modo Escuro" : "Modo Claro";
    },
  },
};
</script>

<style lang="sass">
.drawer-bottom
  position: absolute
  bottom: 0
  left: 0
  width: 100%
  background-color: #363636

.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item
  padding-left: 30px !important
</style>

