import store from "@/store";

export default () => [
  {
    texto: "Cadastros",
    icone: "mdi-account-plus",
    name: "cadastro",
    itens: [
      {
        texto: "Usuários",
        icone: "mdi-account",
        link: "/usuarios",
        mostrar: store.getters["usuario/temPermissao"](
          "listar.empresas.usuarios"
        ),
      },
      {
        texto: "Permissões",
        icone: "mdi-account-group",
        link: "/permissoes",
        mostrar:
          store.getters["usuario/temPermissao"]("mostrar.grupos.permissoes") ||
          store.getters["usuario/temPermissao"]("mostrar.empresas"),
      },
      {
        texto: "Grupos",
        icone: "mdi-calendar-range",
        link: "/grupos",
        mostrar: store.getters["usuario/temPermissao"](""),
      },
      {
        texto: "Todos Planos",
        icone: "mdi-cash-multiple",
        link: "/planos",
        mostrar: store.getters["usuario/temPermissao"](),
      },
      {
        texto: "Meu Plano",
        icone: "mdi-account-credit-card-outline",
        link: "/MeuPlano",
        mostrar: store.getters["usuario/temPermissao"]("listar.planos"),
      },

      {
        texto: "Empresas",
        icone: "mdi-office-building-outline",
        link: "/empresas",
        mostrar: store.getters["usuario/temPermissao"](),
      },
      {
        texto: "Administração Configuração das áreas",
        icone: "mdi-office-building-cog-outline",
        link: "/area_configuracaoAdmin",
        mostrar: store.getters["usuario/temPermissao"](),
      },
      {
        texto: "Configuração das áreas",
        icone: "mdi-office-building-cog",
        link: "/area_configuracaoUser",
        mostrar:
          store.getters["usuario/temPermissao"]("listar.empresas.usuarios") &&
          !store.getters["usuario/temPermissao"](),
      },
      {
        texto: "Áreas",
        icone: "mdi-chart-tree",
        link: "/areas",
        mostrar: store.getters["usuario/temPermissao"]("listar.empresas.areas"),
      },
      {
        texto: "Feriados",
        icone: "mdi-calendar-range",
        link: "/feriados",
        mostrar: store.getters["usuario/temPermissao"]("listar.feriados"),
      },
      {
        texto: "Clientes Mensalistas",
        icone: "mdi-account-tie",
        link: "/clientes",
        mostrar: store.getters["usuario/temPermissao"]("listar.clientes"),
      },
    ],
  },
  {
    texto: "Relatórios",
    icone: "mdi-file-chart-outline",
    itens: [
      {
        texto: "Arrecadação Diária",
        icone: "mdi-chart-box-outline",
        link: "/arrecadacao-diaria",
        mostrar: store.getters["usuario/temPermissao"](
          "relatorio.arrecadacoes.diarias"
        ),
      },
      {
        texto: "Arrecadação Caixa",
        icone: "mdi-chart-box-outline",
        link: "/arrecadacao-caixa-operador",
        mostrar: store.getters["usuario/temPermissao"](
          "fechamento.caixas.lancamentos"
        ),
      },
      {
        texto: "Fechamento Caixa",
        icone: "mdi-chart-box-outline",
        link: "/fechamento-caixa",
        mostrar: store.getters["usuario/temPermissao"](
          "relatorio.fechamentos.operadores"
        ),
      },
      {
        texto: "Consulta Movimentação",
        icone: "mdi-chart-box-outline",
        link: "/consulta-movimentacao",
        mostrar: store.getters["usuario/temPermissao"](
          "relatorio.movimentacoes.veiculos"
        ),
      },
    ],
  },
  {
    texto: "Configurações",
    icone: "mdi-cog",
    name: "configuracao",
    itens: [
      {
        texto: "Empresa",
        icone: "mdi-office-building-outline",
        link: "/empresas/" + store.state.usuario.usuario.empresa_id + "/editar",
        mostrar:
          store.getters["usuario/temPermissao"]("editar.empresas") ||
          store.getters["usuario/temPermissao"]("mostrar.empresas"),
      },
      {
        texto: "Usuários",
        icone: "mdi-account-edit",
        link: "/usuariosEmpresas",
        mostrar: store.getters["usuario/temPermissao"](),
      },
    ],
  },
];
